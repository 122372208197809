.clients-container {
    width: 100%; /* Ensure the container takes up the full width of the screen */
    margin: 20px auto;
    padding: 20px;
    text-align: left;
    overflow-y: auto; /* Ensure vertical scrolling is enabled */
  }
  
  .clients-container h1 {
    margin-bottom: 40px;
    font-size: 2.5em;
    color: #333;
    text-align: center;
  }
  
  .clients-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Distribute columns across full width */
    gap: 20px;
    width: 100%; /* Ensure the grid takes up the full width */
  }
  
  .client-tile {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease;
    height: auto; /* Adjust height based on content */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .client-tile:hover {
    transform: translateY(-10px);
  }
  
  .client-logo {
    max-width: 100%; /* Allow logos to fill the width of the tile */
    height: auto;
    margin-bottom: 10px;
  }
  
  .client-tile p {
    margin: 0;
    font-size: 1.2em;
    color: #555;
    text-align: center;
  }
  