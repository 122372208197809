.other-products {
    padding: 20px;
    text-align: center;
  }
  
  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .product-tile {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .product-tile:hover {
    transform: translateY(-5px);
  }
  
  .product-tile:active {
    transform: translateY(0);
  }
  