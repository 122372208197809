.test-benches-container {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    text-align: left;
  }
  
  .test-benches-container h1 {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 40px;
  }
  
  .tiles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 0 20px;
  }
  
  .tile {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .tile:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .tile a {
    color: #333;
    text-decoration: none;
    font-weight: 500;
  }
  
  .tile a:hover {
    color: #007bff;
  }
  