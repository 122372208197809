/* Reset and layout settings */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevent any scrolling */
}

.App {
  font-family: 'Roboto', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

nav {
  background-color: #333;
}

.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.menu li {
  position: relative;
}

.menu li a, .dropbtn {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 20px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.menu li a:hover, .dropdown:hover .dropbtn {
  background-color: #555;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #444;
  min-width: 230px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 4px;
  padding: 10px 0;
  left: 0; /* Aligns the dropdown to the left side of the parent */
}

.dropdown-content a {
  color: white;
  padding: 12px 20px;
  text-decoration: none;
  display: block;
  text-align: left; /* Ensure text is left-aligned */
}

.dropdown-content a:hover {
  background-color: #555;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  display: block;
  text-align: left; /* Left-aligns the text within each dropdown item */
  padding-left: 20px; /* Add extra padding to further align the text */
}

.dropdown-content a i {
  margin-right: 10px;
}

