.certifications-container {
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    text-align: left;
  }
  .certifications-container h1 {
    margin-bottom: 40px;
    font-size: 2.5em;
    color: #333;
    text-align: center;
  }
  
  .certifications-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr); /* Exactly 7 columns */
    gap: 40px; /* Increase the gap between rows */
    width: 100%;
    justify-content: center;
    align-items: start; /* Align items to the top of the grid area */
  }
  
  .certificate-tile {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 250px; /* Increase the height to accommodate image and text */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out the image and text */
    align-items: center;
    cursor: pointer;
  }
  
  .certificate-tile:hover {
    transform: translateY(-10px);
  }
  
  .certificate-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .certificate-tile p {
    margin: 0;
    font-size: 1.1em;
    color: #555;
    text-align: center;
  }

  .certificate-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
    image-rendering: -webkit-optimize-contrast; /* Improve image rendering */
    image-rendering: crisp-edges; /* Make edges appear sharper */

  }
  
  .certificate-tile.zoomed {
    transform: scale(1.3); /* Increase the zoom effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 10; /* Ensure the zoomed tile appears above others */
    position: relative;
  }
  
  .certificate-tile img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  
  .certificate-tile p {
    margin: 0;
    font-size: 1.1em;
    color: #555;
    text-align: center;
    word-wrap: break-word; /* Ensure text wraps within the tile */
  }