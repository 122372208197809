.contact-us-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-us-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-us-container p {
    line-height: 1.6;
  }
  
  .contact-us-container h2, .contact-us-container h3 {
    color: #333;
    margin-top: 20px;
  }
  