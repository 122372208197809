/* General settings */
.home-container {
  text-align: center;
  padding: 0;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Hero Image Section */
.hero-image-container {
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  position: relative;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */
  display: block;
}

/* Banner settings */
.banner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  background-color: transparent; /* Make the banner background transparent */
  z-index: 10;
}

.top-banner {
  top: 50px; /* Adjust based on your ribbon height */
}

.bottom-banner {
  bottom: 0;
}

.banner-content {
  display: inline-block;
  padding: 10px 0;
  font-size: 1.4em;
  color: white;
  animation: scroll-banner 10s linear infinite;
}

/* Animation for scrolling banners */
@keyframes scroll-banner {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* About Section */
.about-tile {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  text-align: left;
}

.about-tile h2 {
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
}

.about-tile p {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1.6;
  color: #666;
}

/* Footer settings */
footer {
  margin-top: 20px;
  padding: 20px 0;
  background-color: #333;
  color: #fff;
}

.social-media-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.social-media-links li {
  display: inline-block;
  margin: 0 10px;
}

.social-media-links a {
  color: #fff;
  font-size: 1.5em;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-media-links a:hover {
  color: #007bff;
}

/* Navigation Menu */
.menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  position: relative;
}

.menu li {
  position: relative;
}

.menu li a {
  color: white;
  text-decoration: none;
  padding: 14px 20px;
  display: block;
}

.menu li a:hover {
  background-color: #555;
}

/* Dropdown Content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #444;
  min-width: 200px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure dropdowns have a high z-index */
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #555;
}

/* Dropdown Hover Effects */
.menu li:hover .dropdown-content {
  display: block;
}

/* Sub-dropdown Content */
.sub-dropdown-content {
  display: none;
  position: absolute;
  left: 100%; /* Position the submenu to the right of the parent menu */
  top: 0;
  background-color: #444;
  min-width: 200px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1001; /* Ensure submenus are also above other content */
}

.sub-dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.sub-dropdown-content a:hover {
  background-color: #555;
}

/* Sub-dropdown Hover Effects */
.dropdown-content .sub-dropdown:hover .sub-dropdown-content {
  display: block;
}
.sub-dropdown:hover .sub-dropdown-content {
  display: block;
}
